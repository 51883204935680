import React from "react";
import { Box, Text, VStack } from "@chakra-ui/react";

const Guide = () => {
  return (
    <Box
      p={4}
      bg="white"
      margin="0 auto"
      justifyContent="center"
      paddingTop="110px"
      display="flex"
      alignItems="center"
      width="calc(100% - 40px)"
      maxWidth="768px"
      textAlign="left"
    >
      <VStack
        spacing={4}
        align="left"
        gap="0"
        width="100%"
        justifyContent="center"
      >
        <Text fontSize="2xl" fontWeight="bold" paddingBottom={5}>
          利用規約
        </Text>
        <Text fontSize="14px">
          この利用規約（以下、「本規約」といいます。）は、Today's
          Live!!（以下、「本サイト」といいます。）の利用条件を定めるものです。ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サイトをご利用いただきます。
          <br />
          <br />
          第1条. 定義
          <br />
          <br />
          本規約では、以下の用語を使用します。
          <br />
          1.「Today's
          Live!!」とは、URLに「https://todays-music.live」を含むサイトを指します。
          <br />
          2.「ユーザー」とは、本サイトを使用する個人又は法人をいいます。
          <br />
          <br />
          第2条. 本サイトの目的
          <br />
          <br />
          本サイトはユーザーの方に音楽ライブに関わる有益な情報を提供することで、本サイト利用者が訪れるライブを選ぶ際の参考として活用していただくことを目的としています。
          <br />
          <br />
          <br />
          第3条. 規約への同意
          <br />
          <br />
          1. ユーザーは本規約の定めに則って本サイトを利用しなければなりません。
          <br />
          2.
          本規約は、ユーザーと本サイトの利用に関わる一切の権利義務関係に適用されるものとします。
          <br />
          3.
          本サイトに関し、本規約のほか、ご利用にあたってのルール・ガイドライン等、各種の定め（以下、「個別規定」といいます。）を設けることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
          <br />
          4.
          本規約の規定が個別規定と異なる場合には、本規約において特段の定めなき限り、個別規定の定めが優先されるものとします。
          <br />
          <br />
          第4条. プライバシーポリシー及びCookie情報の取扱いについて
          <br />
          <br />
          本サイトは、ユーザーの個人情報及びCookie情報の取扱いについて、別途定めたプライバシーポリシーに従って、適切に取り扱うものとし、ユーザーはこれに同意するものとします。
          <br />
          <br />
          第5条. 本サイトの利用について
          <br />
          <br />
          1. 知的財産権
          <br />
          (1)本サイトに関する著作権はすべて本サイト又は正当な権利を有する権利者に帰属します。各権利所有者におかれましては、万一掲載内容に問題がございましたら、ご本人様よりお問い合わせください。
          <br />
          (2)本サイトに関する特許、著作権（著作権法第27条及び第28条に定める権利を含みます。）その他の知的財産権は、すべて本サイト又は本サイトにその利用を許諾した権利者に帰属します。ユーザーは、本サイトの全部又は一部を無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用その他当該知的財産権を侵害する行為をしてはならないものとします。
          <br />
          (3)ユーザーは、著作物となりうる掲載内容の一部について、本サイト及び本サイトより正当に権利を取得した第三者及び当該第三者から権利を承継した者に対し、著作者人格権（公表権、氏名表示権及び同一性保持権を含みます。）を行使しません。
          <br />
          2. 免責
          <br />
          本サイトが提供する情報、コンテンツについて可能な限り正確な情報を掲載するよう努めておりますが、その完全性、正確性、適用性、有用性、利用可能性、
          安全性、確実性等につきいかなる保証も一切しません。
          <br />
          3. 本サイトの提供
          <br />
          ユーザーは本サイトを利用するにあたり、必要な機器、通信手段及び電力などを自らの責任と費用で用意しなければなりません。
          <br />
          <br />
          第6条. ユーザーの禁止事項
          <br />
          <br />
          1.
          ユーザーは、本サイトの利用にあたり、以下の行為を行ってはならないものとします。
          <br />
          (1)特定の企業、組織に利益又は不利益を与えることを目的とした行為
          <br />
          (2)第三者を誹謗、中傷、名誉、プライバシーを傷つける行為又はその恐れがある行為
          <br />
          (3)第三者の商標権、著作権その他の知的財産権を侵害する行為又はその恐れがある行為
          <br />
          (4)本サービスを商業目的で利用する行為
          <br />
          (5)有害なコンピュータープログラム等を送信又は書き込む行為
          <br />
          (6)本サービスを通じて入手した情報を利用して、第三者に対して金銭などを要求又は不利益、損害を与える行為
          <br />
          (7)本サービスへ断続的な機械的アクセス行為、コンテンツをダウンロードする行為又はそれに類する行為
          <br />
          (8)その他、公序良俗に反する記載を行うなど、本サイトが本サービスの利用を適当でないと判断した場合
          <br />
          2.
          ユーザーが前項に違反した場合、又は本サイトの運営上不適当と判断する行為を行った場合は、当該ユーザーに対して、本サイトの利用停止、損害賠償請求等の必要な措置（法的措置を含む）を取ることができるものとします。
          <br />
          3.
          本サイトは、前項に基づく本サイトの措置によりユーザーに生じた損害について、一切の責任を負いません。
          <br />
          <br />
          第7条. 本サイトの提供の停止等
          <br />
          <br />
          1.
          本サイトは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サイトの全部又は一部の提供を停止又は中断することができるものとします。
          <br />
          (1)本サイトにかかるコンピュータシステムの保守点検又は更新を行う場合
          <br />
          (2)地震、落雷、火災、停電又は天災などの不可抗力により、本サイトの提供が困難となった場合
          <br />
          (3)コンピュータ又は通信回線等が事故により停止した場合
          <br />
          (4)本サイトの運営上その他の理由で一時的な停止が必要と判断した場合
          <br />
          2.
          本サイトは、本サイトの提供の停止又は中断により、ユーザー又は第三者が被ったいかなる不利益又は損害についても、一切の責任を負わないものとします。
          <br />
          <br />
          第8条. 反社会勢力等の排除
          <br />
          <br />
          1.
          ユーザーは、現在及び将来において、次の各号に掲げる事項に該当しないことを、本サイトに対して表明し保証するものとします。
          <br />
          (1)暴力団、暴力団員、暴力団準構成員、暴力団関係者、総会屋、その他これらに準ずる者（以下暴力団員等）
          <br />
          (2)暴力団員等でなくなってから5年を経過しない者
          <br />
          (3)暴力団又は暴力団員等が経営を支配し、又は経営に実質的に関与している者
          <br />
          (4)暴力団又は暴力団員等と密接な関係（資金提供、利益供与及び密接交際を含む）を有する者
          <br />
          2.
          ユーザーは、現在及び将来において、次の各号に掲げる行為を行わないことを、本サイトに対して表明し保証するものとします。
          <br />
          (1)暴力的要求行為
          <br />
          (2)法的責任を超えた不当な要求行為
          <br />
          (3)暴行・脅迫・強要・偽計・業務妨害行為、及びその他の違法行為
          <br />
          (4)前号の他、不当な要求行為
          <br />
          3.
          本サイトは、ユーザーが第１項及び前項各号のいずれかに該当することが明らかになった場合、事前の通知なく当該ユーザーに対して、本サイトの利用停止、ユーザー登録の抹消、損害賠償請求等の必要な措置（法的措置を含む）を取ることができるものとします。また、当該措置により当該ユーザーに対して生ずる損害について、本サイトは一切賠償する責任がないこととします。
          <br />
          <br />
          第9条. サービス内容の変更等
          <br />
          <br />
          本サイトは、ユーザーに通知することなく、本サイトの内容を変更し、又は本サイトの提供を中止又は終了することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
          <br />
          <br />
          第10条. 利用規約の変更
          <br />
          <br />
          本サイトは、必要と判断した場合には、ユーザーに通知することなくいつでも本規約及び個別規定を変更することができるものとします。その場合、本サイト上での告知その他本サイトが適当と認める方法で告知するものとします。ユーザーが、当該告知後に本サイトを利用した場合、当該ユーザーは変更後の規約に同意したものとみなします。
          <br />
          <br />
          第11条. 準拠法・裁判管轄
          <br />
          <br />
          1. 本規約の解釈にあたっては、日本法を準拠法とします。
          <br />
          2.
          本サイトに関して何らかの紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          <br />
        </Text>
      </VStack>
    </Box>
  );
};

export default Guide;
