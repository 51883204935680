import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";

// DateNavigator コンポーネントの props の型定義
interface DateNavigatorProps {
  currentDate: Date;
  onPrev: () => void;
  onNext: () => void;
}

// DateNavigator コンポーネント
export const DateNavigator: React.FC<DateNavigatorProps> = ({
  currentDate,
  onPrev,
  onNext,
}) => {
  // 現在の日付をフォーマット
  const formattedDateSlash = formatDateSlash(currentDate);

  // コンポーネントの描画
  return (
    <Box
      as="h2"
      display="flex"
      alignItems="center"
      justifyContent="center"
      paddingTop="85px"
      paddingBottom="2px"
    >
      {/* 前日ボタン */}
      <Button onClick={onPrev} mx={4} variant="link">
        &lt;&lt;
      </Button>
      {/* 現在の日付 */}
      <Text width={140} textAlign="center" fontSize={18} fontWeight="bold">
        {formattedDateSlash}
      </Text>
      {/* 翌日ボタン */}
      <Button onClick={onNext} mx={4} variant="link">
        &gt;&gt;
      </Button>
    </Box>
  );
};

// 日付をフォーマットする関数
const formatDateSlash = (date: Date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const weekdays = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."];
  const weekday = weekdays[date.getDay()];
  return `${year}/${month}/${day} ${weekday}`;
};
