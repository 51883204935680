// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      // ここにグローバルスタイルを追加します
      body: {
        fontFamily: "Arial, sans-serif",
        bg: "gray.200",
        color: "black",
      },
    },
  },
});

export default theme;
