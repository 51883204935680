import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Text,
  Link,
  AspectRatio,
} from "@chakra-ui/react";

// Modal コンポーネントの props の型定義
interface ModalProps {
  content: string[];
  onClose: () => void;
  isOpen: boolean;
}

// yyyy-mm-dd を yyyy/mm/dd 曜日 に変換する関数
const formatDate = (dateString: string) => {
  const days = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."];
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const dayOfWeek = days[date.getDay()];
  return `${year}/${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")} ${dayOfWeek}`;
};

// Google Maps の埋め込みURLを生成する関数
const createGoogleMapsUrl = (place: string) => {
  const encodedPlace = encodeURIComponent(place);
  return `https://maps.google.com/maps?q=${encodedPlace}&output=embed`;
};

// Modal コンポーネント
export const MyModal: React.FC<ModalProps> = ({ content, onClose, isOpen }) => {
  const order = [2, 3, 0, 4, 5, 1, 6];
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        bg="#f5f5f5"
        padding={3}
        mx="1rem"
        width="90%"
        maxWidth="690px"
      >
        <ModalCloseButton size="sm" />
        {order.map((index) => {
          const item = content[index];
          let component;
          switch (index) {
            case 0:
              component = (
                <Box
                  className="date"
                  position="relative"
                  fontSize="xs"
                  marginBottom={1}
                  width="100%"
                  maxWidth="calc(100% - 10px)"
                  color="gray.600"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  paddingLeft={1}
                >
                  {formatDate(item)}
                </Box>
              );
              break;
            case 1:
              component = (
                <Box
                  className="place"
                  position="relative"
                  fontSize="xs"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  paddingLeft={1}
                  marginBottom={1}
                >
                  {item}
                </Box>
              );
              break;
            case 2:
              component = (
                <Box
                  className="event"
                  color="#0095ff"
                  position="relative"
                  width="100%"
                  maxWidth="calc(100% - 10px)"
                  fontWeight="bold"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  marginTop={4}
                  paddingLeft={1}
                  marginBottom={2}
                >
                  {item}
                </Box>
              );
              break;
            case 3:
              component = (
                <Box
                  className="act"
                  color="black"
                  fontWeight="bold"
                  position="relative"
                  fontSize="sm"
                  maxWidth="calc(100% - 20px)"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  paddingLeft={1}
                  marginBottom={1}
                >
                  {item}
                </Box>
              );
              break;
            case 4:
              component = (
                <Box
                  className="time"
                  position="relative"
                  fontSize="xs"
                  width="60%"
                  maxWidth="100%"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  color="gray.600"
                  paddingLeft={1}
                  marginBottom={1}
                >
                  {item}
                </Box>
              );
              break;
            case 5:
              component = (
                <Box
                  className="price"
                  position="relative"
                  height="15px"
                  fontSize="xs"
                  maxWidth="100%"
                  whiteSpace="normal"
                  overflowWrap="break-word"
                  wordBreak="break-all"
                  color="gray.600"
                  paddingLeft={1}
                  marginBottom={1}
                >
                  {item}
                </Box>
              );
              break;
            case 6:
              component = (
                <Box
                  textAlign="right" // 右寄せにする
                >
                  <Text
                    bg="blue.800"
                    _hover={{ bg: "blue.600" }}
                    color="white"
                    borderRadius="md"
                    display="inline-block"
                    paddingX={2}
                    marginBottom={1}
                  >
                    <Link href={item} isExternal fontSize="xs">
                      詳細はこちら &gt;&gt;
                    </Link>
                  </Text>
                </Box>
              );
              break;
            default:
              component = <Box>{item}</Box>;
          }
          return <React.Fragment key={index}>{component}</React.Fragment>;
        })}
        {/* Google Maps の地図を追加 */}
        <Box marginTop={4}>
          <AspectRatio ratio={16 / 9}>
            <iframe
              src={createGoogleMapsUrl(content[1])}
              title="map"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen={false}
              aria-hidden="false"
              tabIndex={0}
            ></iframe>
          </AspectRatio>
        </Box>
      </ModalContent>
    </Modal>
  );
};
