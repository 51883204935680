import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes, useLocation } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import theme from "./theme";

import "./App.css";
import CsvData from "./components/CsvData";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy"; // 追加
import Guide from "./components/Guide";

function App() {
  const location = useLocation();
  const analytics = getAnalytics();

  useEffect(() => {
    // ページビューイベントをトラッキング
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
  }, [location, analytics]); // locationが変更されるたびにこのエフェクトを実行

  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Routes>
        <Route path="/" element={<CsvData />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/guide" element={<Guide />} />
      </Routes>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
