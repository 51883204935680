import React, { useState } from "react";
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import { useCsvData } from "./useCsvData";
import { MyModal } from "./Modal";
import { DateNavigator } from "./DateNavigator";

// CsvData コンポーネント
const CsvData: React.FC = () => {
  // モーダルウィンドウの表示状態を管理する state
  const [showModal, setShowModal] = useState(false);
  // モーダルウィンドウに表示する内容を管理する state
  const [modalContent, setModalContent] = useState<string[]>([]);
  // カスタムフックを使用して CSV データと関連する関数を取得
  const { currentDate, rows, noLive, handlePrevDate, handleNextDate } =
    useCsvData();

  // ボタンクリック時の処理：モーダルウィンドウに表示する内容を設定し、モーダルウィンドウを表示
  const handleButtonClick = (content: string[]) => {
    setModalContent(content);
    setShowModal(true);
  };

  // モーダルウィンドウの閉じるボタンクリック時の処理：モーダルウィンドウを非表示に
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // コンポーネントの描画
  return (
    <Box>
      {/* 日付ナビゲーター */}
      <DateNavigator
        currentDate={currentDate}
        onPrev={handlePrevDate}
        onNext={handleNextDate}
      />
      {/* ライブデータが存在しない場合のメッセージ */}
      {noLive ? (
        <Text>ライブデータが取得できませんでした。</Text>
      ) : (
        // ライブデータの表示
        rows.map((row, index) =>
          index > 0 ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                key={index}
                onClick={() => handleButtonClick(row)}
                bg="#f5f5f5"
                _hover={{ bg: "#ececec" }}
                height="120px"
                width="calc(100% - 40px)"
                maxWidth="768px"
                my={1}
                mx={5}
                textAlign="left"
                justifyContent="flex-start"
              >
                <VStack align="start" gap="0" width="100%">
                  <Box
                    className="place"
                    bg="blackAlpha.800"
                    color="white"
                    fontSize="sm"
                    textAlign={"end"}
                    position="absolute"
                    bottom={0}
                    right={0}
                    px={2}
                    width={"100%"}
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    borderBottomRightRadius="md"
                    borderBottomLeftRadius="md"
                  >
                    {row[1]}
                  </Box>
                  <Box
                    className="event"
                    position="relative"
                    left={0}
                    top={0}
                    mx={3}
                    maxWidth="calc(100% - 20px)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginBottom={2}
                    color="#0095ff"
                  >
                    {row[2]}
                  </Box>
                  <Box
                    className="row3"
                    position="relative"
                    height="20px"
                    left={0}
                    top={0}
                    mx={3}
                    maxWidth="calc(100% - 20px)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginBottom={2}
                  >
                    {row[3]}
                  </Box>
                  <Box
                    className="row4"
                    position="relative"
                    height="15px"
                    fontSize="xs"
                    ml={3}
                    maxWidth="calc(100% - 20px)"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    marginBottom={1}
                    color={"gray.600"}
                  >
                    {row[4]}
                  </Box>
                  <Box
                    className="row5"
                    position="relative"
                    height="15px"
                    fontSize="xs"
                    ml={3}
                    marginBottom={2}
                    width="100%"
                    maxWidth="100%"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    color={"gray.600"}
                  >
                    {row[5]}
                  </Box>
                </VStack>
              </Button>
            </Box>
          ) : null
        )
      )}
      {/* モーダルウィンドウ */}
      {showModal && (
        <MyModal
          content={modalContent}
          onClose={handleCloseModal}
          isOpen={showModal}
        />
      )}
    </Box>
  );
};

export default CsvData;
