import { useState, useEffect } from "react";
import Papa from "papaparse";

// カスタムフック useCsvData
export const useCsvData = () => {
  // CSV データの行を管理する state
  const [rows, setRows] = useState<string[][]>([]);
  // 現在の日付を管理する state
  const [currentDate, setCurrentDate] = useState(new Date());
  // ライブデータが存在しないかどうかを管理する state
  const [noLive, setNoLive] = useState(false);

  // 現在の日付をフォーマット
  const formattedDate = formatDate(currentDate);

  // コンポーネントのマウント時および currentDate が変更された時に CSV ファイルを読み込む
  useEffect(() => {
    // CSV ファイルのパスを生成
    const csvFilePath =
      process.env.PUBLIC_URL + `/data/${formattedDate}_SKZ.csv`;

    // CSV ファイルをフェッチしてパースする
    fetch(csvFilePath)
      .then((response) => response.text())
      .then((data) => {
        // HTML が返ってきた場合はエラーとする
        if (data.trim().startsWith("<!DOCTYPE html>")) {
          throw new Error("CSVファイルが存在しない");
        }
        // CSV データをパース
        const parsedData = Papa.parse(data, {
          skipEmptyLines: true,
        }).data as string[][];
        // パースしたデータを state にセット
        setRows(parsedData);
        setNoLive(false);
      })
      .catch((error) => {
        console.error("CSVファイルの読み込みに失敗しました", error);
        setNoLive(true);
      });
  }, [formattedDate]);

  // 前日の日付に変更する関数
  const handlePrevDate = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 1);
    setCurrentDate(prevDate);
  };

  // 翌日の日付に変更する関数
  const handleNextDate = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 1);
    setCurrentDate(nextDate);
  };

  // カスタムフックから返す値
  return { currentDate, rows, noLive, handlePrevDate, handleNextDate };
};

// 日付をフォーマットする関数
const formatDate = (date: Date) => {
  return date.toISOString().slice(0, 10).replace(/-/g, "");
};
