import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Text, Link } from "@chakra-ui/react";

const Footer = () => {
  return (
    <Box as="footer" p={3} bg="blue.800" marginTop={3} textAlign="center">
      <Link as={RouterLink} to="/guide" color="white" fontSize="10px">
        利用規約
      </Link>
      <Link
        as={RouterLink}
        to="/privacy-policy"
        color="white"
        fontSize="10px"
        paddingLeft="10px"
        borderLeft="1px solid rgba(255,255,255,.3)"
        marginLeft="10px"
      >
        プライバシーポリシー
      </Link>
      <Link
        href="https://forms.gle/SAn5hqhiUcvfg2G96"
        color="white"
        fontSize="10px"
        paddingLeft="10px"
        borderLeft="1px solid rgba(255,255,255,.3)"
        marginLeft="10px"
      >
        お問い合わせ
      </Link>
      <Text
        as="p"
        color="white"
        fontSize="10px"
        textAlign="center"
        marginTop={1}
      >
        copylight Today's Live!!
      </Text>
    </Box>
  );
};

export default Footer;
