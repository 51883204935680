import React from "react";
import { Box, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const Header = () => {
  return (
    <Box
      as="header"
      bg="blue.800"
      px={4}
      py={2}
      color="white"
      position="fixed"
      width="100%"
      marginBottom={1}
      zIndex="100"
    >
      <Heading as="h1">
        <Link as={RouterLink} to="/" _hover={{ textDecoration: "none" }}>
          Today’s Live!!
          <Box
            as={"span"}
            bg="#00a0bd"
            borderRadius="md"
            fontSize={14}
            marginLeft={2}
            px={2}
          >
            in SHIMOKITA
          </Box>
        </Link>
      </Heading>
      <Box fontSize={14}>下北沢エリアのライブ情報まとめサイト</Box>
    </Box>
  );
};

export default Header;
