import React from "react";
import { Box, Text, VStack, Link } from "@chakra-ui/react";
import reactStringReplace from "react-string-replace";

const PrivacyPolicy = () => {
  const text = `
    本ポリシーに関するお問い合わせはこちらよりご連絡ください。
  `;

  const replacedText = reactStringReplace(text, "こちら", (match, i) => (
    <Link
      href="https://forms.gle/SAn5hqhiUcvfg2G96"
      isExternal
      color="blue.500"
      key={i}
    >
      {match}
    </Link>
  ));

  return (
    <Box
      px={5}
      py={5}
      paddingTop="110px"
      margin="0 auto"
      bg="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="calc(100% - 40px)"
      maxWidth="768px"
      textAlign="left"
    >
      <VStack
        spacing={4}
        align="left"
        gap="0"
        width="100%"
        justifyContent="center"
      >
        <Text fontSize="2xl" fontWeight="bold" paddingBottom={5}>
          プライバシーポリシー
        </Text>
        <Text fontSize="14px" paddingBottom={5}>
          Today's
          Live!!（以下、「当サイト」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
        </Text>
        <Text fontSize="14px">
          ・個人情報の利用目的
          <br />
          <br />
          当サイトでは、お問い合わせいただく際、名前やメールアドレスなどの個人情報を入力いただく場合がございます。取得した個人情報は、お問い合わせに対する回答や必要な情報をメールなどでご連絡する場合に利用させていただくものであり、これらの目的以外では利用いたしません。
          <br />
          <br />
          ・アクセス解析ツールについて
          <br />
          <br />
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を使用しています。このGoogleアナリティクスはデータの収集のためにCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。
          この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関しての詳細はGoogleアナリティクスサービス利用規約のページやGoogleポリシーと規約ページをご覧ください。
          <br />
          <br />
          ・広告配信サービスについて
          <br />
          <br />
          当サイトはGoogle及びGoogleのパートナーウェブサイト（第三者配信事業者）の提供する広告「Google
          Adsense（グーグルアドセンス）」を設置しております。
          <br />
          広告配信プロセスにおいてデータを収集するためにCookieを使用し、当サイトを含めた過去のアクセス情報に基づいて広告を配信することが可能になります。
          Cookieを使用することにより、GoogleやGoogleのパートナーは当サイトや他のサイトへのアクセス情報に基づいて、適切な広告を当サイト上で訪問者様に表示できます。
          <br />
          ユーザー様は、Googleアカウントの広告設定ページでパーソナライズド広告を無効にできます。
          <br />
          または、www.aboutads.infoにアクセスして頂き、第三者配信事業者がパーソナライズド広告の掲載で使用する
          Cookie を無効にできます。
          <br />
          その他、Googleの広告における、Cookieの取り扱いについての詳細は、Googleのポリシーと規約ページをご覧ください。
          <br />
          <br />
          ・本プライバシーポリシーの変更
          <br />
          <br />
          当サイトは、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。
          <br />
          本プライバシーポリシーは、事前の予告なく変更することがあります。
          <br />
          本プライバシーポリシーの変更は、当サイトに掲載された時点で有効になるものとします。
          <br />
          <br />
          ・お問い合わせ
          <br />
          <br />
        </Text>
        <Text fontSize="14px">{replacedText}</Text>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
